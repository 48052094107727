<template>
  <v-card tile>
    <v-toolbar tile dark :color="mainColor">
      <v-btn icon :disabled="step == 1" @click="step--">
        <v-icon color="white">
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <v-toolbar-title>Create Post</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="close">
        <v-icon color="white">
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-3 px-3">
      <!-- <p class="subtitle-1 mb-0 dark--text">Post in</p> -->
      <v-window v-model="step" touchless>
        <v-window-item :value="1">
          <div class="px-5">
            <v-subheader class="px-0 body-1"
              >What kind of post is it?</v-subheader
            >
            <v-item-group mandatory v-model="postType">
              <v-row>
                <template v-for="(type, i) in postTypes">
                  <v-col cols="6" :key="i">
                    <!--                         @click="type.name != 'long-text' ? toggle() : null"
 -->
                    <v-item
                      v-slot:default="{ active, toggle }"
                      :value="type.name"
                    >
                      <v-card
                        :color="active ? 'pink darken-2' : 'pink accent-1'"
                        :elevation="active ? '3' : '0'"
                        dark
                        @click="toggle()"
                        v-on:click="onClick"
                      >
                        <v-card-text class="py-7 text-center">
                          <!-- <v-badge
                            v-if="type.name == 'long-text'"
                            right
                            top
                            content="soon come 🙏🏾"
                          > -->
                          <!-- <v-avatar size="60px" tile>
                            <v-icon size="60px" color="white">
                              {{ type.icon }}
                            </v-icon>
                          </v-avatar> -->
                          <!-- </v-badge> -->

                          <v-avatar size="60px" tile>
                            <v-icon size="60px" color="white">
                              {{
                                type.icon === 1
                                  ? mdiImageOutline
                                  : type.icon === 2
                                  ? mdiPencil
                                  : type.icon === 3
                                  ? mdiTypewriter
                                  : null
                              }}
                            </v-icon>
                          </v-avatar>
                        </v-card-text>

                        <v-card-actions class="text-center">
                          <span
                            class="subtitle-2 white-text"
                            style="width: 100%"
                          >
                            {{ type.title }}
                          </span>
                        </v-card-actions>
                      </v-card>
                    </v-item>
                  </v-col>
                </template>
              </v-row>
            </v-item-group>

            <p class="subtitle-2">
              {{ postTypeText }}
            </p>
          </div>
        </v-window-item>
        <v-window-item :value="2">
          <circle-select
            :circles="user.circles"
            :selectedCircle="selectedCircle"
            :circleError="circleError"
            v-on:update:selected-circle="selectedCircle = $event"
            @niqqa-hasnt-joined-a-circle="niqqaHasntJoinedACircle = true"
          ></circle-select>

          <!-- POSTER -->
          <keep-alive>
            <component
              :is="poster"
              v-bind="posterProps"
              :ref="poster"
              v-on="posterEvents"
            ></component
            >\
          </keep-alive>

          <!-- POSTER -->
        </v-window-item>
      </v-window>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions class="d-flex justify-space-between">
      <v-btn
        color="color-purple purple-outline"
        :disabled="step == 1"
        @click="step--"
      >
        Back
      </v-btn>

      <v-btn v-if="step == 1" depressed color="bg-purple" dark @click="step++">
        Next
      </v-btn>

      <v-btn
        v-if="postReady || (postType == 'short-text' && step == 2)"
        depressed
        color="bg-purple"
        dark
        @click="post"
      >
        Post
      </v-btn>
    </v-card-actions>

    <!-- Join Circles bottom sheet -->
    <v-bottom-sheet
      v-model="niqqaHasntJoinedACircle"
      inset
      style="max-height: 50vh; height: 50vh;"
    >
      <circles-list @joined-circle="setJoinedCircle"></circles-list>
    </v-bottom-sheet>
    <!-- Join Circles bottom sheet -->
  </v-card>
</template>

<script>
// import http from '../plugins/http';
import { ImagePoster, ShortTextPoster, LongTextPoster } from './posters';
import CirclesList from './circles-list.vue';
import CircleSelect from './circle-select.vue';
// icons
import {
  mdiArrowLeft,
  mdiClose,
  mdiImageOutline,
  mdiPencil,
  mdiTypewriter
} from '@mdi/js';
export default {
  name: 'new-post',
  components: {
    CirclesList,
    ImagePoster,
    ShortTextPoster,
    LongTextPoster,
    CircleSelect
  },
  props: {
    mainColor: {
      type: String
    }
  },
  data: () => ({
    postReady: false,
    selectedCircle: null,
    step: 1,
    postType: 'image',
    niqqaHasntJoinedACircle: false,
    circleError: false,
    postTypes: [
      { name: 'image', title: 'Image Post', icon: 1 },
      { name: 'short-text', title: 'Quick Text', icon: 2 },
      { name: 'long-text', title: 'Blog Text', icon: 3 }
    ],
    clicks: 0,
    delay: 200,
    timer: null,
    // icons
    mdiArrowLeft,
    mdiClose,
    mdiImageOutline,
    mdiPencil,
    mdiTypewriter
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    poster: function() {
      let poster = 'ImagePoster';

      switch (this.postType) {
        case 'image':
          poster = 'ImagePoster';
          break;
        case 'short-text':
          poster = 'ShortTextPoster';
          break;
        case 'long-text':
          poster = 'LongTextPoster';
          break;
        default:
          break;
      }

      return poster;
    },
    posterProps: function() {
      let posterProps = { yeet: true };

      switch (this.postType) {
        case 'image':
          posterProps = { selectedCircle: this.selectedCircle };
          break;
        case 'short-text':
          posterProps = { selectedCircle: this.selectedCircle };
          break;
        case 'long-text':
          posterProps = { selectedCircle: this.selectedCircle };
          break;
        default:
          break;
      }

      return posterProps;
    },
    posterEvents: function() {
      let posterEvents = {
        'update:selected-circle': () => {
          'selectedCircle = $event';
        },
        'close-new-post-dialog': this.close,
        'circle-error': this.onCircleError,
        'new-posts': this.onNewPost
      };

      switch (this.postType) {
        case 'image':
          posterEvents = {
            ...posterEvents,
            'post-ready': this.readyPost
          };
          break;
        case 'short-text':
          posterEvents;
          break;
        case 'long-text':
          posterEvents = {
            ...posterEvents,
            'post-ready': this.readyPost
          };
          break;
        default:
          break;
      }

      return posterEvents;
    },
    postTypeText() {
      let postTypeDescription =
        'Use this in circles to share pictoral content. duh';

      switch (this.postType) {
        case 'image':
          postTypeDescription =
            'Use this in circles to share pictoral content. duh';
          break;
        case 'short-text':
          postTypeDescription =
            'Use this to share peoms or just short write-ups';
          break;
        case 'long-text':
          postTypeDescription = 'Format for writing longer stuff, try it abeg';
          break;
        default:
          break;
      }

      return postTypeDescription;
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (to.name == 'View Circle' && to.params.circle_slug) {
        this.selectedCircle = this.$store.getters.belongsToCircle(
          to.params.circle_slug
        )
          ? this.$store.getters.findCircle(this.$route.params.circle_slug)
          : '';
      }
    }
  },
  methods: {
    close() {
      this.$emit('close-new-post-dialog');
      this.selectedCircle = null;
      this.step = 1;
    },
    post: function() {
      switch (this.postType) {
        case 'image':
          this.$refs['ImagePoster'].post();
          break;
        case 'short-text':
          this.$refs['ShortTextPoster'].post();
          break;
        case 'long-text':
          this.$refs['LongTextPoster'].post();
          break;
        default:
          break;
      }
    },
    readyPost() {
      this.postReady = true;
    },
    onCircleError($event) {
      this.circleError = $event;
    },
    onNewPost() {
      this.$emit('new-posts');
    },
    setJoinedCircle(circle) {
      this.selectedCircle = circle;
      this.niqqaHasntJoinedACircle = false;
    },
    onClick: function(event) {
      this.clicks++;
      if (this.clicks === 1) {
        var self = this;
        this.timer = setTimeout(function() {
          console.log(event.type);
          self.clicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        console.log('dblclick');
        this.step++;
        this.clicks = 0;
      }
    }
  },
  mounted() {
    if (this.$route.name == 'View Circle' && this.$route.params.circle_slug) {
      this.selectedCircle = this.$store.getters.belongsToCircle(
        this.$route.params.circle_slug
      )
        ? this.$store.getters.findCircle(this.$route.params.circle_slug)
        : null;
    }
  }
};
</script>

<style scoped>
.circle-name {
  font-size: 1rem;
}
</style>
