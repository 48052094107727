<template>
  <v-card tile>
    <v-card-title class="mb-1 pink lighten-2" style="font-size: 19px"
      >Abeg join a circle before you can post</v-card-title
    >

    <v-card-text>
      <v-skeleton-loader
        transition="fade"
        v-if="!fetched"
        height="40px"
        type="text"
        width="130px"
      >
      </v-skeleton-loader>
      <v-card-subtitle v-else class="secondary--text">
        Hot circles right now
        <v-icon color="deep-orange">
          {{ mdiFire }}
        </v-icon>
      </v-card-subtitle>

      <v-row v-if="!fetched">
        <v-col cols="12">
          Getting Circles
        </v-col>
        <v-col cols="12">
          <v-progress-linear
            color="pink darken-2"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col> </v-row
      ><v-list v-else dense class="transparent">
        <template v-for="(circle, index) in circles">
          <v-list-item :key="circle.title">
            <template v-slot:default>
              <v-list-item-avatar>
                <v-avatar v-if="circle.display_picture" color="white" size="40">
                  <v-img width="40" height="40" :src="circle.display_picture">
                  </v-img>
                </v-avatar>

                <v-avatar v-else :color="color(index)" size="40">
                  <span class="white--text">
                    {{ circle.name.charAt(0).toUpperCase() }}
                  </span>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-medium body-1"
                  v-text="circle.name"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  small
                  depressed
                  :loading="buttons[`${circle.id}-button-loading`]"
                  :color="
                    !buttons[`${circle.id}-button-joined`]
                      ? 'pink darken-2'
                      : 'success'
                  "
                  @click.stop="join(circle)"
                  class="white--text"
                >
                  <template v-if="!buttons[`${circle.id}-button-joined`]">
                    <v-icon small class="white--text">
                      {{ mdiPlus }}
                    </v-icon>
                    Join
                  </template>

                  <v-scale-transition
                    v-if="buttons[`${circle.id}-button-joined`]"
                  >
                    <v-icon color="white" class="font-weight-bold white--text">
                      {{ mdiCheck }}
                    </v-icon>
                  </v-scale-transition>
                </v-btn>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider v-if="index + 1 < circles.length" :key="index"></v-divider>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import http from '@/plugins/http';
// icons
import { mdiFire, mdiCheck, mdiPlus } from '@mdi/js';
export default {
  data() {
    return {
      circles: [],
      fetched: false,
      fetchError: false,
      joinLoading: false,
      joinedSuccessfully: false,
      buttons: {},
      // icons
      mdiFire,
      mdiCheck,
      mdiPlus
    };
  },
  methods: {
    fetchCircles() {
      this.fetched = false;
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      let headers = {
        Authorization: `token ${token}`
      };

      if (!token) {
        headers = {};
      }

      http
        .get('/v1/circles/', { headers })
        .then(response => {
          this.circles = response.data.results;
          this.circles.forEach(c => {
            this.$set(this.buttons, `${c.id}-button-loading`, false);
            this.$set(this.buttons, `${c.id}-button-joined`, false);
          });
          console.log('Response =>', response);
        })
        .catch(response => {
          this.fetchError = true;
          console.log('Error Response => ', response);
        })
        .finally(() => {
          this.fetched = true;
        });
    },
    join(circle) {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };
      this.buttons[`${circle.id}-button-loading`] = true;
      http
        .post(
          `/v1/circle/${circle.slug}/users/`,
          { data: 'yarns' },
          { headers }
        )
        .then(response => {
          console.log('res => ', response.data);
          this.buttons[`${circle.id}-button-joined`] = true;
          this.$store.dispatch('JOIN_CIRCLE', circle);

          //   this.$router.push({
          //     name: 'View Circle',
          //     params: { name: circleName }
          //   });
          //   close here...
          setTimeout(() => {
            this.$emit('joined-circle', circle);

            this.$store.dispatch('SHOW_TOAST', {
              message: 'Joined circle successfully',
              style: 'info'
            });
          }, 1000);
        })
        .catch(response => {
          console.log('res => ', response.data);
        })
        .finally(() => {
          this.buttons[`${circle.id}-button-loading`] = false;
        });
    },
    color(index) {
      let colors = [
        'cyan lighten-2',
        'green lighten-2',
        'indigo lighten-2',
        'pink lighten-2'
      ];

      return colors[index];
    }
  },
  mounted() {
    this.fetchCircles();
  }
};
</script>
<style scoped></style>
