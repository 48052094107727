<template>
  <div id="wrapper">
    <div id="content">
      <v-app-bar fixed color="white" dense flat tile>
        <v-app-bar-nav-icon>
          <v-btn text icon @click="$emit('close-editor', false)">
            <v-icon color="secondary">
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-app-bar-nav-icon>

        <v-toolbar-title class="caption">{{
          preview ? 'PREVIEW' : 'EDITOR'
        }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn @click="$emit('close-editor', output)" text color="primary">
          Done
        </v-btn>
      </v-app-bar>
      <!-- <div
        class="transparent d-flex align-center justify-space-between px-3 py-4"
      >


   

        <v-btn @click="$emit('close-editor', output)" text color="primary">
          Done
        </v-btn>
      </div> -->

      <!-- Text Area! -->
      <div class="mx-3 pt-13 editor">
        <!-- <v-textarea
          background-color="transparent"
          class="font"
          autofocus
          hide-details
          auto-grow
          color="black"
          v-model="text"
        ></v-textarea> -->
        <textarea
          ref="textarea"
          autofocus="autofocus"
          v-model="text"
          v-show="!preview"
          v-on:input="autoExpand"
          class="font markdown-input"
        ></textarea>

        <!-- Output div :) -->

        <div
          v-show="preview"
          id="output-div"
          v-html="output"
          class="font markdown-display"
        ></div>
      </div>

      <!-- Text areaA -->

      <v-footer
        absolute
        color="grey lighten-1"
        elevation="1"
        style="z-index: 200"
        class="font-weight-medium fixed"
      >
        <div
          class="d-flex align-center justify-space-between"
          elevation="0"
          style="width: 100%"
          dense
        >
          <v-spacer></v-spacer>

          <v-divider vertical></v-divider>

          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn-toggle
                v-model="preview"
                group
                background-color="none"
                color="purple"
                borderless
                dense
              >
                <v-btn v-on="tooltip" text :ripple="false" :value="true">
                  {{ preview ? 'Continue writing' : 'Preview' }}
                  <v-icon v-if="preview">
                    {{ mdiPencil }}
                  </v-icon>

                  <v-icon v-else>
                    {{ mdiEye }}
                  </v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
            {{ preview ? 'Continue writing' : 'Preview' }}
          </v-tooltip>
        </div>
      </v-footer>

      <!-- Typing area! -->
    </div>
  </div>
</template>

<script>
import marked from 'marked';
// icons
import { mdiClose, mdiEye, mdiPencil } from '@mdi/js';
export default {
  name: 'LongTextEditor',
  data: () => ({
    text: '',
    preview: false,
    mdiClose,
    mdiEye,
    mdiPencil
  }),
  computed: {
    output: function() {
      return marked(this.text);
    },
    isStarted: function() {
      return this.text != '' || this.text != ' ';
    }
  },
  methods: {
    autoExpand({ target: field }) {
      // Reset field height
      field.style.height = 'inherit';

      // Get the computed styles for the element
      var computed = window.getComputedStyle(field);

      // Calculate the height
      var height =
        parseInt(computed.getPropertyValue('border-top-width'), 10) +
        parseInt(computed.getPropertyValue('padding-top'), 10) +
        field.scrollHeight +
        parseInt(computed.getPropertyValue('padding-bottom'), 10) +
        parseInt(computed.getPropertyValue('border-bottom-width'), 10);

      field.style.height = height + 'px';
    }
  },
  mounted() {
    // this.$refs.textarea.focus();
  }
};
</script>

<style scoped>
body {
  overflow-y: hidden;
}

#wrapper:before {
  content: '';
  float: left;
  height: 100%;
}

#wrapper {
  height: 100%;
  background-color: whitesmoke;
}

#content {
  background-color: whitesmoke;
}

#content::after {
  content: '';
  display: block;
  clear: both;
}

.font {
  font-size: 20px !important;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: transparent !important;
  border: none;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  background-color: transparent !important;
  border: none;
}

.fixed {
  position: fixed !important;
}

.editor {
  position: relative;
}

.markdown-input {
  border: none;
  outline: none;
  /* z-index: 100; */
  height: 200px;
  margin-bottom: 100px;
  position: relative;
  min-height: 5em;
  width: 100%;
}

.markdown-display {
  min-height: 5em;
  position: relative;
  border: none;
  outline: none;
  top: 0;
  width: 100%;
}
</style>
