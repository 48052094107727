var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"value":_vm.noCaptionError,"open-on-click":false,"color":_vm.noCaptionError ? 'warning' : 'pink lighten-2',"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-3",attrs:{"label":"Caption","required":""},model:{value:(_vm.form.caption),callback:function ($$v) {_vm.$set(_vm.form, "caption", $$v)},expression:"form.caption"}},tooltip))]}}])},[_vm._v(" "+_vm._s(_vm.noCaptionError ? 'Please add a caption' : 'Let it make sense')+" ")]),_c('image-uploader',{attrs:{"previewImage":{
      width: '100%',
      height: '300px',

      contain: false
    },"cardSheet":{
      class: 'd-flex align-center',
      height: '300px',
      width: '100%'
    },"card":{
      class: 'mt-3',
      width: '100%',
      height: '300px'
    },"image":_vm.form.image},on:{"update:image":function($event){return _vm.$set(_vm.form, "image", $event)},"uploaded-image-src":_vm.imageUploaded}}),_c('v-overlay',{model:{value:(_vm.uploading),callback:function ($$v) {_vm.uploading=$$v},expression:"uploading"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }