<template>
  <v-menu v-model="openMenu" :open-on-click="false">
    <template v-slot:activator="{ on: menu }">
      <v-list class="px-0">
        <v-tooltip :value="circleError" color="warning" bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-list-item
              class="px-0"
              link
              v-on="{ ...tooltip, ...menu }"
              v-on:click="niqqaWantsToSelectCircle"
            >
              <v-list-item-avatar
                :color="selectedCircle ? 'indigo' : 'grey'"
                size="40"
                class="mr-3"
              >
                <template v-if="selectedCircle">
                  <v-avatar
                    v-if="selectedCircle.display_picture"
                    color="white"
                    size="40"
                  >
                    <v-img
                      width="40"
                      height="40"
                      :src="selectedCircle.display_picture"
                    >
                    </v-img>
                  </v-avatar>

                  <v-avatar v-else color="primary" size="40">
                    <span class="white--text">
                      {{ selectedCircle.name.charAt(0).toUpperCase() }}
                    </span>
                  </v-avatar>
                </template>

                <template v-else>
                  <v-avatar
                    class="white--text text-center text-capitalize "
                    size="40px"
                    color="secondary lighten-3"
                  >
                    <span style="font-size: 24px">
                      ?
                    </span>
                  </v-avatar>
                </template>

                <!-- Here fetch users circles -->
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="title font-weight-bold secondary--text text-capitalize"
                >
                  {{ selectedCircle ? selectedCircle.name : 'Select Circle' }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <span>Please select a circle to post in</span>
        </v-tooltip>
      </v-list>
    </template>
    <v-list class="px-0">
      <v-list-item
        v-for="(circle, index) in circles"
        @click="$emit('update:selected-circle', circle)"
        :key="index"
        link
      >
        <v-list-item-avatar color="green" size="40px">
          <v-avatar v-if="circle.display_picture" color="white" size="40">
            <v-img width="40" height="40" :src="circle.display_picture">
            </v-img>
          </v-avatar>

          <v-avatar
            v-else
            class="white--text text-capitalize "
            size="40px"
            color="green"
          >
            <span class="white--text">
              {{ circle.name.charAt(0).toUpperCase() }}
            </span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline secondary--text">{{
            circle.name
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: 'CircleSelect',
  props: {
    circles: {
      type: Array
    },
    circleError: {
      type: Boolean
    },
    selectedCircle: {
      type: Object
    }
  },
  data: function() {
    return {
      openMenu: false
    };
  },
  methods: {
    niqqaWantsToSelectCircle(e) {
      e.preventDefault();
      this.openMenu = false;

      if (this.circles.length == 0) {
        this.$emit('niqqa-hasnt-joined-a-circle', true);
        return;
      }

      this.$nextTick(() => {
        this.openMenu = true;
      });
    }
  }
};
</script>
