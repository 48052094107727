<template>
  <div>
    <!-- Should be a component! -->
    <v-tooltip
      :value="noTitleError"
      :open-on-click="false"
      :color="noTitleError ? 'warning' : 'pink lighten-2'"
      top
    >
      <template v-slot:activator="{ on: tooltip }">
        <v-text-field
          label="Title"
          v-on="tooltip"
          v-model="form.caption"
          required
        ></v-text-field>
      </template>
      {{ noTitleError ? 'Your post needs a title' : 'Short and sweet :)' }}
    </v-tooltip>

    <!-- pooop -->

    <v-card class="d-flex flex-column">
      <div
        class="d-flex align-center justify-space-between px-3 py-1"
        elevation="0"
        dense
      >
        <!-- <v-overflow-btn
          :items="fonts"
          label="Select font"
          hide-details
          class="pa-0"
        ></v-overflow-btn> -->

        <v-btn-toggle
          v-model="form.alignment"
          mandatory
          color="primary"
          dense
          group
        >
          <v-btn
            v-for="(item, i) in alignments"
            :key="i"
            :value="item.class"
            icon
            :color="form.background"
            :title="item.name"
          >
            <v-icon
              >{{
                item.icon === 1
                  ? mdiFormatAlignLeft
                  : item.icon === 2
                  ? mdiFormatAlignCenter
                  : item.icon === 3
                  ? mdiFormatAlignRight
                  : null
              }}
            </v-icon>
          </v-btn>
        </v-btn-toggle>

        <v-menu>
          <template v-slot:activator="{ on }">
            <v-avatar v-on="on" :color="form.background" size="35px">
              <v-icon size="20px" color="white">
                {{ mdiFormatColorFill }}</v-icon
              >
            </v-avatar>
          </template>

          <v-list>
            <v-list-item-group v-model="form.background">
              <v-list-item
                v-for="(color, i) in backgrounds"
                :key="i"
                :value="color.class"
              >
                <v-list-item-avatar>
                  <v-avatar :color="color.class" size="35px"> </v-avatar>
                </v-list-item-avatar>

                <v-list-item-title>
                  {{ color.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <v-sheet
        width="100%"
        id="textarea"
        min-height="300px"
        ref="textPost"
        height="300px"
        :color="form.background"
        class="white--text px-10 py-5"
        :class="form.alignment"
        tile
        style="line-height: 28px;flex: 1;font-size: 20px;cursor: text;border: 1px solid white"
        :contenteditable="true"
      >
        {{ form.body }}
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import http from '@/plugins/http';
// icons
import {
  mdiFormatAlignLeft,
  mdiFormatAlignCenter,
  mdiFormatAlignRight,
  mdiFormatColorFill
} from '@mdi/js';
export default {
  name: 'short-text-poster',
  props: {
    selectedCircle: {
      type: Object
    }
  },
  data: () => ({
    noTitleError: false,
    openMenu: false,
    form: {
      caption: '',
      body: '',
      background: 'pink lighten-2',
      type: 'text',
      alignment: 'text-center'
    },
    backgrounds: [
      {
        class: 'pink lighten-2',
        name: 'Weed Eater'
      },
      {
        class: 'green lighten-2',
        name: 'Boluwatife'
      },
      {
        class: 'orange lighten-2',
        name: 'Mar-A-Lago'
      },
      {
        class: 'purple lighten-2',
        name: 'Maryamu'
      },
      {
        name: 'Vibrant',
        class: 'secondary lighten-2'
      }
    ],
    alignments: [
      {
        name: 'Left Align',
        icon: 1,
        class: 'text-left'
      },
      {
        name: 'Center Align',
        icon: 2,
        class: 'text-center'
      },
      {
        name: 'Right Align',
        icon: 3,
        class: 'text-right'
      }
    ],
    mdiFormatColorFill,
    mdiFormatAlignRight,
    mdiFormatAlignLeft,
    mdiFormatAlignCenter
  }),
  methods: {
    post() {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`,
        'Content-Type': 'multipart/form-data'
      };

      let formData = new FormData();

      formData.append('caption', this.form.caption);

      formData.append('body', this.$refs.textPost.$el.innerText);

      formData.append('bg_color', this.form.background);

      formData.append('alignment', this.form.alignment);

      if (!this.selectedCircle) {
        this.$emit('circle-error', true);
        setTimeout(() => {
          this.$emit('circle-error', false);
        }, 2500);
        return;
      }

      if (this.form.caption == '') {
        this.noTitleError = true;
        setTimeout(() => {
          this.noTitleError = false;
        }, 2500);
        return;
      }

      this.$store.dispatch('TOGGLE_LOADER');

      http
        .post(
          `/v1/circle/${this.selectedCircle.slug}/post/text/?post-type=short-text`,
          formData,
          {
            headers
          }
        )
        .then(response => {
          if (response.data.success) {
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Post created successfully',
              style: 'success'
            });

            this.$store.dispatch('TOGGLE_NEW_POSTS');

            // TODO: Reload circles page after posting from here...

            this.$emit('close-new-post-dialog');
            this.$emit('new-posts');

            console.log('res => ', response.data);
            this.form.caption = '';
            this.form.body = '';
            this.$refs.textPost.$el.innerText = '';
            this.form.alignment = 'text-center';
            this.form.background = 'pink lighten-2';
          } else {
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Something went wrong, please try again',
              style: 'error'
            });
          }
        })
        .catch(response => {
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Something went wrong, please try again',
            style: 'error'
          });
          console.log('res => ', response.data);
        })
        .finally(() => {
          this.$store.dispatch('TOGGLE_LOADER');
        });
    }
  },
  mounted() {
    // automatically set focus
    // this.$refs.textPost.$el.innerHtml = '';
    this.$refs.textPost.$el.innerText = '';
    this.$refs.textPost.$el.focus();
  }
};
</script>

<style scoped>
#textarea:empty::before {
  color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: bold;
}

#textarea[data-placeholder]:not([data-placeholder='']):empty::before {
  content: attr(data-placeholder);
}

#textarea:empty::before {
  color: rgba(255, 255, 255, 0.6) !important;
  /* font-size: 24px; */
  font-weight: bolder;
  content: 'Oya write something';
}
</style>
