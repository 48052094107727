<template>
  <div>
    <!-- Should be a component! -->
    <v-tooltip
      :value="noTitleError"
      :open-on-click="false"
      :color="noTitleError ? 'warning' : 'pink lighten-2'"
      top
    >
      <template v-slot:activator="{ on: tooltip }">
        <v-text-field
          label="Title"
          v-on="tooltip"
          v-model="form.caption"
          required
        ></v-text-field>
      </template>
      {{ noTitleError ? 'Your post needs a title' : 'Short and sweet :)' }}
    </v-tooltip>

    <v-subheader style="height: 30px" class="px-0 body-1"
      >Your header picture</v-subheader
    >

    <image-uploader
      v-bind:previewImage="{
        width: '100%',
        height: '150px',

        contain: false
      }"
      v-bind:cardSheet="{
        class: 'd-flex align-center',
        height: '150px',
        width: '100%'
      }"
      v-bind:card="{
        class: 'mt-3',
        width: '100%',
        height: '150px'
      }"
      v-bind:image.sync="form.image"
      @uploaded-image-src="imageUploaded"
    ></image-uploader>

    <p class="caption grey--text text-center mt-2">
      use a good quality picture that is landscape
    </p>

    <v-subheader style="height: 30px" class="px-0 mt-5  body-1"
      >Your text</v-subheader
    >

    <!-- PREVIEW CARD -->
    <v-card
      @click="showEditor = true"
      elevation="0"
      style="border: 4px dotted #00000075"
      min-height="200px"
      flat
    >
      <v-overlay absolute opacity="0" v-if="form.htmlOutput == ''">
        <v-sheet
          color="transparent"
          class="d-flex justify-center  secondary--text lighten-4"
        >
          <span>
            Click to write
            <v-icon color="#00000075">
              {{ mdiPencil }}
            </v-icon>
          </span>
        </v-sheet>
      </v-overlay>
      <v-subheader v-else style="height: 30px" class="px-0 mt-5  body-1"
        >Click to edit
        <v-icon color="#00000075">
          {{ mdiPencil }}
        </v-icon></v-subheader
      >
      <v-card-text v-html="htmlOutput"></v-card-text>
    </v-card>
    <!-- PREVIEW CARD -->

    <v-dialog
      v-model="showEditor"
      fullscreen
      hide-overlay
      :retain-focus="false"
      transition="dialog-bottom-transition"
    >
      <long-text-editor @close-editor="closeEditor"></long-text-editor>
    </v-dialog>
  </div>
</template>

<script>
import http from '@/plugins/http';
import LongTextEditor from './long-text-editor.vue';
import ImageUploader from '@/components/helpers/image-uploader.vue';
// icons
import { mdiPencil } from '@mdi/js';
export default {
  name: 'long-text-poster',
  components: {
    LongTextEditor,
    ImageUploader
  },
  props: {
    selectedCircle: {
      type: Object
    }
  },
  data: () => ({
    noTitleError: false,
    openMenu: false,
    showEditor: false,
    htmlOutput: '',
    form: {
      caption: '',
      body: '',
      image: '',
      type: 'long-text'
    },
    mdiPencil
  }),
  methods: {
    post() {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`,
        'Content-Type': 'multipart/form-data'
      };

      let formData = new FormData();

      formData.append('caption', this.form.caption);

      formData.append('body', this.form.body);

      formData.append('image', this.form.image);

      formData.append('parsed_html', this.htmlOutput);

      formData.append('post_type', 'long-text');

      if (!this.selectedCircle) {
        this.$emit('circle-error', true);
        setTimeout(() => {
          this.$emit('circle-error', false);
        }, 2500);
        return;
      }

      if (this.form.caption == '') {
        this.noTitleError = true;
        setTimeout(() => {
          this.noTitleError = false;
        }, 2500);
        return;
      }

      this.$store.dispatch('TOGGLE_LOADER');

      http
        .post(
          `/v1/circle/${this.selectedCircle.slug}/post/text/?post_type=long-text`,
          formData,
          {
            headers
          }
        )
        .then(response => {
          if (response.data.success) {
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Post created successfully',
              style: 'success'
            });

            this.$store.dispatch('TOGGLE_NEW_POSTS');

            // TODO: Reload circles page after posting from here...

            this.$emit('close-new-post-dialog');
            this.$emit('new-posts');

            console.log('res => ', response.data);
            this.form.caption = '';
            this.form.body = 'Click here to type...';
          } else {
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Something went wrong, please try again',
              style: 'error'
            });
          }
        })
        .catch(response => {
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Something went wrong, please try again',
            style: 'error'
          });
          console.log('res => ', response.data);
        })
        .finally(() => {
          this.$store.dispatch('TOGGLE_LOADER');
        });
    },
    // updateBody(ev) {
    //   console.log(ev.target.innerText);
    //   this.form.body = ev.target.innerText;
    //   console.log(ev.target.innerText);
    // },
    closeEditor(output) {
      this.showEditor = false;

      if (output) {
        this.htmlOutput = output;
        this.$emit('post-ready');
      }
    },
    imageUploaded() {
      console.log('yeet, image uploaded! :)');
    }
  }
};
</script>

<style scoped></style>
