<template>
  <div>
    <v-tooltip
      :value="noCaptionError"
      :open-on-click="false"
      :color="noCaptionError ? 'warning' : 'pink lighten-2'"
      top
    >
      <template v-slot:activator="{ on: tooltip }">
        <v-text-field
          class="mt-3"
          label="Caption"
          v-on="tooltip"
          v-model="form.caption"
          required
        ></v-text-field>
      </template>
      {{ noCaptionError ? 'Please add a caption' : 'Let it make sense' }}
    </v-tooltip>

    <image-uploader
      v-bind:previewImage="{
        width: '100%',
        height: '300px',

        contain: false
      }"
      v-bind:cardSheet="{
        class: 'd-flex align-center',
        height: '300px',
        width: '100%'
      }"
      v-bind:card="{
        class: 'mt-3',
        width: '100%',
        height: '300px'
      }"
      v-bind:image.sync="form.image"
      @uploaded-image-src="imageUploaded"
    ></image-uploader>

    <v-overlay v-model="uploading">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import http from '@/plugins/http';
import ImageUploader from '@/components/helpers/image-uploader.vue';
export default {
  name: 'image-poster',
  components: {
    ImageUploader
  },
  props: {
    selectedCircle: {
      type: Object
    }
  },
  data() {
    return {
      uploadedImage: '',
      circleError: false,
      noCaptionError: false,
      openMenu: false,
      uploading: false,
      form: {
        caption: '',
        description: '<deprecated :)>',
        image: null
      }
    };
  },

  methods: {
    upload() {
      this.$refs.fileUploader.$refs.input.click();
    },
    imageUploaded() {
      console.log('Image Uploaded: ');
      this.$emit('post-ready');

      // this.form.image = file;

      // let reader = new FileReader();

      // reader.addEventListener('load', e => {
      //   this.uploadedImage = e.target.result;
      //   this.$emit('post-ready');
      // });

      // reader.readAsDataURL(file);
    },
    post: function() {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`,
        'Content-Type': 'multipart/form-data'
      };

      let formData = new FormData();

      formData.append('image', this.form.image, `${Math.random() * 10000}.png`);

      formData.append('caption', this.form.caption);

      formData.append('description', this.form.description);

      if (!this.selectedCircle) {
        this.$emit('circle-error', true);

        setTimeout(() => {
          this.$emit('circle-error', false);
        }, 2500);
        return;
      }

      if (this.form.caption == '') {
        this.noCaptionError = true;
        setTimeout(() => {
          this.noCaptionError = false;
        }, 2500);
        return;
      }

      this.uploading = true;
      // this.$store.dispatch('TOGGLE_LOADER');
      http
        .post(
          `/v1/circle/${this.selectedCircle.slug}/post/image/?post_type=image`,
          formData,
          {
            headers
          }
        )
        .then(response => {
          console.log('res => ', response.data);
          this.form.image = '';
          this.form.caption = '';
          this.form.description = '';

          this.uploadedImage = '';

          // Add a view post here...

          if (response.data.success) {
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Post created successfully',
              style: 'success'
            });

            this.$store.dispatch('TOGGLE_NEW_POSTS');

            // TODO: Reload circles page after posting from here...

            this.$emit('close-new-post-dialog');
            this.$emit('new-posts');
          } else {
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Something went wrong, please try again',
              style: 'error'
            });
          }
        })
        .catch(response => {
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Something went wrong, please try again',
            style: 'error'
          });
          console.log('res => ', response.data);
        })
        .finally(() => {
          this.uploading = false;
          // this.$store.dispatch('TOGGLE_LOADER');
        });
    }
  }
};
</script>

<style scoped></style>
