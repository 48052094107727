var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"value":_vm.noTitleError,"open-on-click":false,"color":_vm.noTitleError ? 'warning' : 'pink lighten-2',"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Title","required":""},model:{value:(_vm.form.caption),callback:function ($$v) {_vm.$set(_vm.form, "caption", $$v)},expression:"form.caption"}},tooltip))]}}])},[_vm._v(" "+_vm._s(_vm.noTitleError ? 'Your post needs a title' : 'Short and sweet :)')+" ")]),_c('v-subheader',{staticClass:"px-0 body-1",staticStyle:{"height":"30px"}},[_vm._v("Your header picture")]),_c('image-uploader',{attrs:{"previewImage":{
      width: '100%',
      height: '150px',

      contain: false
    },"cardSheet":{
      class: 'd-flex align-center',
      height: '150px',
      width: '100%'
    },"card":{
      class: 'mt-3',
      width: '100%',
      height: '150px'
    },"image":_vm.form.image},on:{"update:image":function($event){return _vm.$set(_vm.form, "image", $event)},"uploaded-image-src":_vm.imageUploaded}}),_c('p',{staticClass:"caption grey--text text-center mt-2"},[_vm._v(" use a good quality picture that is landscape ")]),_c('v-subheader',{staticClass:"px-0 mt-5  body-1",staticStyle:{"height":"30px"}},[_vm._v("Your text")]),_c('v-card',{staticStyle:{"border":"4px dotted #00000075"},attrs:{"elevation":"0","min-height":"200px","flat":""},on:{"click":function($event){_vm.showEditor = true}}},[(_vm.form.htmlOutput == '')?_c('v-overlay',{attrs:{"absolute":"","opacity":"0"}},[_c('v-sheet',{staticClass:"d-flex justify-center  secondary--text lighten-4",attrs:{"color":"transparent"}},[_c('span',[_vm._v(" Click to write "),_c('v-icon',{attrs:{"color":"#00000075"}},[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")])],1)])],1):_c('v-subheader',{staticClass:"px-0 mt-5  body-1",staticStyle:{"height":"30px"}},[_vm._v("Click to edit "),_c('v-icon',{attrs:{"color":"#00000075"}},[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")])],1),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.htmlOutput)}})],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","retain-focus":false,"transition":"dialog-bottom-transition"},model:{value:(_vm.showEditor),callback:function ($$v) {_vm.showEditor=$$v},expression:"showEditor"}},[_c('long-text-editor',{on:{"close-editor":_vm.closeEditor}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }